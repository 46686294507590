import { SnapshotExtractionStatuses } from "@/components/constants.js"
export default {
  cameraName: {
    component: "TextFieldSearchFilter",
    name: "camera",
  },
  cameraExid: {
    component: "TextFieldSearchFilter",
  },
  status: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: "" }, ...SnapshotExtractionStatuses],
      value: "",
    },
  },
}
