import { secondsToHms } from "@evercam/shared/utils"

export default [
  {
    value: "delete",
    text: "",
    width: "2%",
    sortable: false,
    align: "center",
    copyable: false,
  },
  {
    value: "progress",
    text: "",
    width: "2%",
    sortable: false,
    align: "center",
    copyable: false,
  },
  {
    value: "id",
    text: "ID",
    width: "5%",
    sortable: true,
    align: "center",
    filterable: true,
  },
  {
    value: "cameraName",
    text: "Camera",
    width: "10%",
    sortable: true,
    filterable: true,
  },
  {
    value: "cameraExid",
    text: "Camera ID",
    width: "10%",
    sortable: true,
    filterable: true,
  },
  {
    value: "fromDate",
    text: "From Date",
    width: "5%",
    sortable: true,
    filterable: true,
  },
  {
    value: "toDate",
    text: "To Date",
    width: "5%",
    sortable: true,
    filterable: true,
  },
  {
    value: "injectToCr",
    text: "Inject to CR",
    width: "6%",
    sortable: true,
    align: "center",
    filterable: true,
  },
  {
    value: "jpegsToDropbox",
    text: "JPEGs to DropBox",
    width: "8%",
    sortable: true,
    align: "center",
    filterable: true,
  },
  {
    value: "interval",
    text: "Interval",
    width: "6%",
    align: "center",
    sortable: true,
    filterable: true,
    toStringFn: (item, key) => secondsToHms(item[key]),
  },
  {
    value: "status",
    text: "Status",
    width: "6%",
    sortable: true,
    align: "center",
    filterable: true,
  },
  {
    value: "type",
    text: "Type",
    width: "6%",
    align: "center",
    sortable: true,
    filterable: true,
  },
  {
    value: "notes",
    text: "Notes",
    sortable: false,
    filterable: true,
  },
  {
    value: "requestor",
    text: "Requestor",
    sortable: true,
    filterable: true,
  },
  {
    value: "createdAt",
    text: "Created At",
    width: "8%",
    sortable: true,
    filterable: true,
  },
]
